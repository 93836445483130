.ReviewAttribution {
    display:flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    /* border: 2px solid red; */
    width: 100%;
    font-family: var(--card-body-font-family);
    font-size: var(--card-body-font-size);
    margin-top: 20px;
}
