.StepTitle {
    font-family: var(--card-title-font-family);
    font-size: var(--card-title-font-size);
    font-weight:600;
    color: var(--periwinkle);
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
    /*border: 2px dotted blue;*/
}