.HeaderMenu {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* align-items: flex-end;
    align-content: flex-end; */
    height: 100%;
    width: 25vw;
    /* border: 2px dotted yellow; */
}
