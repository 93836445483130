.HeroMessage {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    font-family: var(--banner-font-family);
    font-size: var(--banner-font-size);
    /* font-size: 72px; */
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: 1.41px;
    text-align: center;
    color: #fff;
    /*border: 2px dotted blue;*/
}
