.HowItWorksSection {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: 
    '. title title title .'
    '. card1 card2 card3 .'
    ;
    width: 100%;
    height: 95%;
    /* border: 3px solid green; */
    /* margin-bottom: 50px; */

    /* padding: 0; */
    /* align-content: space-between;
    align-items: flex-start; */
}

.title {
    grid-area: title;
    /* border: 3px dotted purple; */
    color: black;
    align-items: center;
    justify-content:center;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 45pt;
    font-weight: semi-bold;
    color: var(--periwinkle)
}

.description {
    padding-top: 10px;
    margin-right: 300px;
    margin-left: 300px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25pt;
    font-weight: semi-bold;
    color: grey;
}

.card1 {
    grid-area: card1;
    /* border: 3px dotted purple; */
    align-items: space-around;
  display: flex;
  padding: 0;
  width: 100%;
  height: 100%;
  justify-content:space-around;
}

.card2 {
    grid-area: card2;
    /* border: 3px dotted purple; */
    align-items: center;
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    justify-content:center;
}

.card3 {
    grid-area: card3;
    /* border: 3px dotted purple; */
      align-items: center;
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content:center;
}
