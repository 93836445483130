.HeroCallToActionButton {
    width: 300px;
    /* height: 90px; */
    /* border-radius: 2px; */
    border: none;
    /* color: purple; */
    /* background-color: var(--periwinkle); */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
}

.HeroCallToActionButton:hover {
    /* color: white;
    background-color: var(--battleship-grey);
    cursor: pointer; */
}