.FrequentlyAskedQuestion {
    display:flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: left;
    align-content: flex-start;
    width: 100%;
    
    /* height: 300px; */
    /* border: 3px solid red; */
}

.FAQSubtitle {
    padding-top: 30px;
    /* padding-left: 25vw; */
    /* border: 3px dotted purple; */
    /* width: 100vw; */
    align-items: left;
    justify-content: left;
    font-family: var(--card-title-font-family);
    font-size: var(--card-title-font-size);
    font-weight: semi-bold;
    color: var(--periwinkle);
}
.body {
    /* padding-left: 25vw; */
    /* height: 100%; */
    /* border: 3px dotted purple; */
    text-align: left;
    font-family: var(--section-subtitle-font-family);
    font-size: var(--section-subtitle-font-size);
    /* font-weight: semi-bold; */
    color: var(--battleship-grey)
}

.MenuSeparator {
    padding-top: 10px;
    width: auto;
    height: 5px;
    object-fit: cover;
    color: var(--battleship-grey);
    /* position: relative; */
   
  }
hr {
    border-top: 0px solid var(--battleship-grey);
}

.MenuSeparator img {
    width: auto;
    height: 5px;
    object-fit:cover;

}

.MenuSeparatorCollapsed {
  width: 75px;
  height: 1px;
  position: relative;

}

.MenuSeparatorCollapsed img {
width: 75px;
position: absolute;

}