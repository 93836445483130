.HatchLogo {
    background-color: transparent;
    height: 100px;
    margin: 0px;
    padding: 0px;
    /* border: 3px solid purple; */
}
.hatchimg {
    /* border: 1px solid #ddd; */
    /* border-radius: 4px; */
    /* padding: 20px; */
    width: 100%;
    height: 100%;
  }
  