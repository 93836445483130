.ReviewCarousel {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
        "Full"
    ;
    /* border: 3px solid red; */
    background-image: linear-gradient(53deg, var(--bright-aqua), #a389d4);
    z-index: 100;

}

.Review {
    height: 100%;
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    z-index: 300;
    /* border: 3px solid red; */
}

.ReviewText {
    flex-grow: 2;
    font-family: var(--section-title-font-family);
    font-size: var(--section-title-font-size);
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
   
    /* border: 3px solid red; */
}

.Image {
    /* height: 100vh; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.ImageBox{
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    grid-row: 1;
    grid-column: 1;
    z-index: 200;
    opacity: 40%;
    display: flex;
}