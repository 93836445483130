.MenuLinkButton {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    font-size: var(--menu-link-font-size);
    font-family: var(--menu-link-font-family);
    width: 100%;
    height: 100%;
    color: white;
    
    /* border: 2px dotted blue; */
}

.MenuLinkButton:link {
    text-decoration: none;
    font-size: var(--menu-link-font-size);
    font-family: var(--menu-link-font-family);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.25px;
}

.MenuLinkButton:hover {text-decoration: none; color: var(--periwinkle);}
