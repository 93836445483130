.FeatureBlockIcon {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    /* border: 2px dotted blue; */
    
}
.hatchimg {
    width: 40px
}
