@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {
    /* Colors */
    --charcoal-grey: #393c40;
    --dark-grey: #212224;
    --very-light-pink: #eaeaea;
    --very-light-blue: #ecedef;
    --dusty-orange: #ff6533;
    --charcoal-grey: #474b4f;
    --light-grey-blue: #aaaeb3;
    --dark-hot-pink: #d90381;
    --light-blue-grey: #c6c9cf;
    --battleship-grey: #6f737a;
    --bright-aqua: #00ffdf;
    --pea: #b2a424;
    --cool-grey: #a4a9b0;
    --periwinkle: #aa80fe;

    /* Fonts (This is isn't working for some readon)*/ 
    /* --banner-font: 5vw, Arial, Helvetica, sans-serif;
    --section-title-font: 3vw, Arial, Helvetica, sans-serif;
    --section-subtitle-font: 2vw, Arial, Helvetica, sans-serif;
    --card-title-font: 1.5vw, Arial, Helvetica, sans-serif;
    --card-body-font: 1vw, Arial, Helvetica, sans-serif; */
    --menu-link-font-size: 1vw;
    --menu-link-font-family: "Open Sans", sans-serif;
    --banner-font-size: 5vw;
    --banner-font-family:  "Open Sans", sans-serif;
    --section-title-font-size: 2vw;
    --section-title-font-family: "Open Sans", sans-serif;
    --section-subtitle-font-size: 1.2vw;
    --section-subtitle-font-family: "Open Sans", sans-serif;
    --card-title-font-size: 1.5vw;
    --card-title-font-family: "Open Sans", sans-serif;
    --card-body-font-size: 1.1vw;
    --card-body-font-family: "Open Sans", sans-serif;
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: inline-block;
  width: 100%;

}

.box {
  height: 100.16px;
  margin: auto;
  width: 115.72px;
}

.box1 {
  -moz-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-animation: move 1s infinite linear;
  -webkit-animation: move 1s infinite linear;
}

#box_1 {
    -moz-animation-delay: .1s;
    -webkit-animation-delay: .1s;
}

@-moz-keyframes move {
    0% {
        -moz-transform: scale(1);
        opacity: 1;
    }
    50% {
        -moz-transform: scale(1);
        opacity: 1;
    }
    60% {
        -moz-transform: scale(1.2);
        opacity: 1;
    }
    70% {
        -moz-transform: scale(1.0);
        opacity: 1;
    }
    80% {
        -moz-transform: scale(1.2);
        opacity: 1;
    }
    90% {
        -moz-transform: scale(1.0);
        opacity: 1;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 1;
    };
}

@-webkit-keyframes move {
    0% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
    60% {
        -webkit-transform: scale(1.2);
        opacity: 1;
    }
    70% {
        -webkit-transform: scale(1.0);
        opacity: 1;
    }
    80% {
        -webkit-transform: scale(1.2);
        opacity: 1;
    }
    90% {
        -webkit-transform: scale(1.0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    };
}

