.CarouselScrollRightButton {
    display:flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    /* border: 2px solid red; */
    width: 100%;
   
}

.arrowImage {
    width: 10%;
    transform: rotate(180deg);
    padding: 20px;
}
.arrowImage:hover {
    cursor: pointer;
}

