.HeroBanner {
    display: grid;
    /* width: 100vw; */
    height: 100%;
    grid-area: TopSection;
    grid-template-columns: auto;
    grid-template-rows: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    grid-template-areas: 
        "Full"
    ;
    object-fit: cover;
}
.Background {
    height: 100%;
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    grid-area: Full;

    z-index: 0;
    background-image: linear-gradient(53deg, var(--bright-aqua), #a389d4);
}

.Title {
    flex-grow: 1;
    font-family:'Courier New', Courier, monospace;
    font-size: 34pt;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: gray;
    /* align-items: center; */
    /* text-align: center; */
}

.HeroImage {
    /* grid-area: Full; */
    /* padding: 20px; */
    /* height: 100vh; */
    width: 100vw;
    /* height: 2000vh; */
    opacity: 40%;

    /* object-fit: cover; */
    object-position: 0vw -25vh;
    /* z-index: 50; */
    /* border: 3px solid yellow */
}
.HeroImageContainer {
    grid-area: Full;
    /* padding: 20px; */
    /* height: 100vh; */
    width: 100vw;
    /* height: 2000vh; */
    /* opacity: 40%; */

    object-fit:cover;
    /* object-position: -40vw -40vh; */
    z-index: 50;
    /* border: 3px solid yellow */
}


.RightColumn {
    display: flex;
    height: 25%;
    flex-flow: column nowrap;
    z-index: 300;
    grid-area: Full;
    margin-right: 100px;
    align-self:center;
    justify-self: right;
    /* border: 3px solid red; */
    align-items:center;
    justify-content: space-between;
    
}
.HeroMessage {
    z-index: 305;
    /* border: 3px solid blue; */
  
}
.HeroCallToActionButton {
    
    /* border: 3px solid yellowgreen */
}



