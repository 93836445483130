.PrivacyPolicyPage {
    width: 100vw;
    height: 100vh;
    /* object-fit: contain; */
    display: flex;
    flex-flow: column nowrap;
    /* z-index: 1000; */
    /* border: 10px solid blue; */
    /* overflow: scroll;
    grid-template-columns: auto; */
    /*grid-template-rows: ["top-start"] 80vh 
    ["top-end" "how-it-works-start"] 60vh
    ["how-it-works-end" "feature-1-start"]90vh
    ["image-overlay-1-start"] 500px 
    ["feature-1-end" "feature-2-start"]50vh 
    ["image-overlay-2-start"]500px 
    ["feature-2-end" "feature-3-start"] 60vh 
    ["image-overlay-3-start"]500px
    ["feature-3-end" "feature-4-start"] 40vh
    ["feature-4-end" "review-carousel-start"] 70vh
    ["review-carousel-end" "footer-start"] 20vh
    ["footer-end"];*/
    /* grid-template-rows: 20vh 100% 20vh;
    grid-template-areas: "TopSection"
    "Body"
    "Footer"
      ; */
}

.Title {
    font-family: OpenSans;
    font-size: 34pt;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b0b0b;
    margin-bottom: 57px;
}

.itemA{
  display: flex;
  flex-flow: column nowrap;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* grid-area: TopSection; */
  z-index: 0;
  background-color: black;
  /* color: white; */
  font-size: 5vw;
  /* text-align: center;
  text-justify: center; */
  /* height: 100%;
  width: 100%; */

    /* border: 5px solid red; */
  }
.itemB {
    grid-area: TopSection;
    z-index: 0;

    
  }
.itemC {
    display: flex;
    flex-flow: column nowrap;
    /* grid-area: Body; */
    text-align:left;
    margin: 100px;
    /* margin: 100px; */
    /* height: 100%; */
    /* border: 3px solid blue; */
}
.itemD{
    /* grid-row: 4; */
    grid-row: 3 / 5;
    grid-column: 1;
    
    /* grid-row: 3 / span 2; */
    /* grid-area: FeatureSection1 ; */
    /* border: 3px solid yellow; */
    z-index: 100;
    
  }

.itemE{
    /* grid-area: ImageOverlay1; */
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto;
    justify-content: center;
    grid-row: 4/6;
    grid-column: 1;
    /* border: 5px solid hotpink; */
    /* background-color: hotpink; */
    z-index: 200;
    width: 100%;
    /* opacity: 20%; */
    padding-right: 500;
    /* margin-right: 500; */
    margin-top: -8vh;
    padding-bottom: 5vh;

}
.itemEA{
    /* grid-area: ImageOverlay1; */
    /* display: grid; */
    grid-column: 2;
    grid-row: 1;
    /* background-color: black; */
    /* border: 3px solid orange */

}

.itemF{
    grid-area: FeatureSection2 ;
    /* border: 5px solid red; */
    z-index: 100;
  }

  .itemG{
    grid-row: 6;
    grid-column: 1;
    z-index: 300;
    width: 60%;
    margin-top: -10vh;
    margin-left: 10vh;
    /* border: 5px solid hotpink; */
    /* background-color: hotpink; */
  }

  .itemH{
    grid-row: 7 / 9;
    grid-column: 1;
    /* border: 5px solid blue; */
    z-index: 200;
  }

  .itemI{
    grid-row: 8;
    grid-column: 1;
    margin-top: -10vh;
    justify-self: right;
    width: 60%;
    /* border: 5px solid hotpink; */
    /* background-color: hotpink; */
    z-index: 300;
  }

  .itemJ{
    grid-area: FeatureSection4;
    /* border: 5px solid blue; */
    z-index: 200;
  }
    .itemK{
    grid-area: ReviewCarouselSection;
    /* border: 5px solid blue; */
    z-index: 200;
  }
  .itemL{
    /* display: flex; */
    /* width: 100%; */
    /* grid-area: Footer; */
    /* border: 5px solid blue; */
    /* z-index: 200; */
  }
  
  
  .container {
    display: grid;
    grid-template-columns: 50px 50px 50px 50px;
    grid-template-rows: auto;
    grid-template-areas: 
      "header header header header"
      "main main . sidebar"
      "footer footer footer footer";
  }