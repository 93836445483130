.FAQSection {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
    /* grid-template-rows: 1fr 3fr; */
    grid-template-areas: 
    'title title title title title'
    '. card1 card1 . .'
    ;
    width: 100vw;
    height: 100%;

    /* border: 3px solid green; */
    margin: 0;
    padding: 0;
    padding-bottom: 100px;
    /* background-image: linear-gradient(53deg, var(--bright-aqua), #a389d4); */
    /*border: 2px dotted blue;*/
}
.FAQTitle {
    grid-area: title;
    /* border: 3px dotted purple; */
    color: black;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    /* height: auto; */
    font-family: var(--section-title-font-family);
    font-size: var(--section-title-font-size);
    font-weight: semi-bold;
    color: var(--periwinkle);
}


.title {
    grid-area: title;
    /* border: 3px dotted purple; */
    
    color: black;
    align-items: center;
    justify-content:center;
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
}

.card1 {
    grid-area: card1;

}

.hatchimg {
    width: 100%;
}

.card2 {
    grid-area: card2;
    flex-flow: column nowrap;
    /* border: 3px dotted purple; */
    align-items: flex-start;
    display: flex;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
}

.feature1 {


}

.feature2 {


}

