.ImageOverlay {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    /*border: 2px dotted blue;*/
}

.hatchimg {
    /* border: 1px solid #ddd;
    border-radius: 4px; */
    padding: 0px;
    padding-left: 0px;
    width: 100%;
  }