.StepIcon {
    /* background-color: var(--periwinkle); */
    
    fill: var(--periwinkle);
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
    /* border: 2px dotted blue; */
}

/* img {
    fill: blue;
} */

.hatchimg {
    /* border: 1px solid #ddd;
    border-radius: 4px; */
    /* padding: 20px; */
    height: 100%;
    width: 100px;
  }
 