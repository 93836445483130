.Footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: var(--periwinkle);
    /* border: 5px dotted yellow; */
}

.copyright {
    background-color: var(--periwinkle);
    color: white;
    padding-bottom: 20px;

   
}
.parent {
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--periwinkle);
    /* width: 100%; */
    /* height: 100%; */

    /* height: 100%; */

    /* margin: 20px; */
}

.Menu{
    display: flex;
    flex-flow: column nowrap;
    width: 65%;
    /* height: 100%; */
    /* border: 3px solid purple; */
}

.Logo{
    /* display: flex; */
    width:  8vw;
    /* height: 50vh; */
    /* border: 2px dotted red; */
    /* align-items: flex-start; */
    /* margin-top: 2%;
    margin-bottom: 5%;*/
    margin-left: 3%;
}
