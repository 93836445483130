.StepDescription {
    font-family: var(--card-body-font-family);
    font-size: var(--card-body-font-size);
    color: var(--battleship-grey);
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    /*border: 2px dotted blue;*/
}
