.FeatureBlockDescriptionWhite {
    /* display:flex; */
 
    /* justify-content: left;
    justify-items: left;
    align-items: left;
    align-content:flex-start; */
    text-align: left;
    width: 100%;
    font-family: var(--section-subtitle-font-family);
    font-size: var(--section-subtitle-font-size);
    font-weight: 100;
    color: white;
    /* border: 2px dotted blue; */
    
}

.FeatureBlockDescriptionGrey {
    /* display:flex; */
 
    /* justify-content: left;
    justify-items: left;
    align-items: left;
    align-content:flex-start; */
    text-align: left;
    width: 100%;
    font-family: var(--section-subtitle-font-family);
    font-size: var(--section-subtitle-font-size);
    font-weight: 100;
    color: var(--battleship-grey);
    /* border: 2px dotted blue; */
    
}