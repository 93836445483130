.FooterMenu {
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
    gap: 20px 20px;
    /* border: 2px dotted blue; */
    height: 100%;
    width: 100%;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    /* margin-top: 4%; */
    /* justify-content: space-around; */

}
