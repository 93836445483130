.FeatureBlockNameWhite {
    display: flex;
    font-family: var(--card-title-font-family);
    font-size: var(--card-title-font-size);
    font-weight: 600;
    color: white;
    justify-content:left;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    /* border: 2px dotted blue; */
}

.FeatureBlockNamePurple {
    display: flex;
    font-family: var(--card-title-font-family);
    font-size: var(--card-title-font-size);
    font-weight: 600;
    color: var(--periwinkle);
    justify-content:left;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    /* border: 2px dotted blue; */
}
