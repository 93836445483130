.FeatureBlock {
    display:flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: left;
    align-content: flex-start;
    width: 90%;
    height: 300px;
    /* border: 3px solid red; */
}

.row1 {
margin-bottom: 20px
}

.row2 {
    margin-bottom: 20px
}
.row3 {
    
}
