.Header {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    /* width: 100vw; */
    height: 100px;
    /* border: 5px solid green; */
    margin: 50px 5px 5px 50px;
}

.Title {
    flex-grow: 1;
    font-family:'Courier New', Courier, monospace;
    font-size: 34px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: gray;
    /* align-items: center; */
    /* text-align: center; */
}

.Menu {
    display: flex;
    flex-flow: row nowrap;
    /* flex-grow: 1; */
    height: 100%;
    width: 50vw;
    /* align-content: center; */
    /* justify-content: right */
}

.Logo {
    display: flex;
    /* flex-grow: 1; */
    align-items: flex-start;
}

.ImageBox {
    display: flex;
    width: 100%;
    justify-content: right;
    justify-items: right;
    align-items: right;
    align-content: right;
    /* border: 5px solid green; */
}
.hatchimg {
    /* border: 1px solid #ddd; */
    /* border-radius: 4px; */
    /* padding: 20px; */
    width: 10vw;

  }