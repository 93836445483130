.CarouselScrollLeftButton {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    /* border: 2px solid red; */
    width: 100%;
    flex-grow: 1;
}

.arrowImage {
    width: 10%;
    padding: 20px;
    /* transform: rotate(180deg); */
}

.arrowImage:hover {
    cursor: pointer;
}
