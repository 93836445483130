.FeatureSectionWhite {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr 3fr;
    grid-template-rows: 1fr 1fr ;
    grid-template-areas: 
    '. card1b card1b . .'
    '. card2b card2b . .'
    ;
    width: 100%;
    height: 100%;
    /* border: 3px solid green; */
    margin: 0;
    padding-top: 30px;
    background-color: white;
    /*border: 2px dotted blue;*/
}

.card1a {
    grid-area: card1a;
    /* border: 3px dotted purple; */
      align-items: center;
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content:center;
}

.card1b {
    grid-area: card1b;
    /* border: 3px dotted green; */
      align-items: center;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content:center;
}

.card2a {
    grid-area: card2a;
    /* border: 3px dotted purple; */
    align-items: center;
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    justify-content:center;
}

.card2b {
    grid-area: card2b;
    /* border: 3px dotted purple; */
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    justify-content:center;
}