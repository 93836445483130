.HowToStepBlock {
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    max-width: 400px;
    /*border: 2px dotted blue;*/
}

.Section1{
    height: 100%
}

.Section2{
    height: 50%
}

.Section3{
    height: 100%
}